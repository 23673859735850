import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./src/styles/global.css";
import React from "react";

// Logs when the client route changes
export const onRouteUpdate = (event) => {
  const search = event.location.search;

  if (search) {
    const params = new URLSearchParams(search);
    const utm_source = params.get("utm_source");
    const utm_medium = params.get("utm_medium");
    const utm_campaign = params.get("utm_campaign");

    if (utm_source) {
      localStorage.setItem("utmSource", utm_source);
    }
    if (utm_medium) {
      localStorage.setItem("utmMedium", utm_medium);
    }
    if (utm_campaign) {
      localStorage.setItem("utmCampaign", utm_campaign);
    }
  }
};
