exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blogs-benefits-of-helicopter-travel-tsx": () => import("./../../../src/pages/blogs/benefits-of-helicopter-travel.tsx" /* webpackChunkName: "component---src-pages-blogs-benefits-of-helicopter-travel-tsx" */),
  "component---src-pages-blogs-chardham-best-service-provider-tsx": () => import("./../../../src/pages/blogs/chardham-best-service-provider.tsx" /* webpackChunkName: "component---src-pages-blogs-chardham-best-service-provider-tsx" */),
  "component---src-pages-blogs-chardham-best-tourist-destinations-tsx": () => import("./../../../src/pages/blogs/chardham-best-tourist-destinations.tsx" /* webpackChunkName: "component---src-pages-blogs-chardham-best-tourist-destinations-tsx" */),
  "component---src-pages-blogs-chardham-yatris-experience-tsx": () => import("./../../../src/pages/blogs/chardham-yatris-experience.tsx" /* webpackChunkName: "component---src-pages-blogs-chardham-yatris-experience-tsx" */),
  "component---src-pages-blogs-guide-to-planning-your-yatra-tsx": () => import("./../../../src/pages/blogs/guide-to-planning-your-yatra.tsx" /* webpackChunkName: "component---src-pages-blogs-guide-to-planning-your-yatra-tsx" */),
  "component---src-pages-blogs-history-of-chardham-unleash-chardham-by-helicopter-tsx": () => import("./../../../src/pages/blogs/history-of-chardham-unleash-chardham-by-helicopter.tsx" /* webpackChunkName: "component---src-pages-blogs-history-of-chardham-unleash-chardham-by-helicopter-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-travel-toolkit-chardham-tsx": () => import("./../../../src/pages/blogs/travel-toolkit-chardham.tsx" /* webpackChunkName: "component---src-pages-blogs-travel-toolkit-chardham-tsx" */),
  "component---src-pages-chardham-by-helicopter-index-tsx": () => import("./../../../src/pages/chardham-by-helicopter/index.tsx" /* webpackChunkName: "component---src-pages-chardham-by-helicopter-index-tsx" */),
  "component---src-pages-chardham-by-helicopter-packages-chardham-1-n-2-d-tsx": () => import("./../../../src/pages/chardham-by-helicopter/packages/chardham-1n-2d.tsx" /* webpackChunkName: "component---src-pages-chardham-by-helicopter-packages-chardham-1-n-2-d-tsx" */),
  "component---src-pages-chardham-by-helicopter-packages-do-dham-tsx": () => import("./../../../src/pages/chardham-by-helicopter/packages/do-dham.tsx" /* webpackChunkName: "component---src-pages-chardham-by-helicopter-packages-do-dham-tsx" */),
  "component---src-pages-chardham-by-helicopter-packages-ek-dham-tsx": () => import("./../../../src/pages/chardham-by-helicopter/packages/ek-dham.tsx" /* webpackChunkName: "component---src-pages-chardham-by-helicopter-packages-ek-dham-tsx" */),
  "component---src-pages-chardham-by-helicopter-packages-index-tsx": () => import("./../../../src/pages/chardham-by-helicopter/packages/index.tsx" /* webpackChunkName: "component---src-pages-chardham-by-helicopter-packages-index-tsx" */),
  "component---src-pages-government-works-tsx": () => import("./../../../src/pages/government-works.tsx" /* webpackChunkName: "component---src-pages-government-works-tsx" */),
  "component---src-pages-heli-celebrations-other-services-tsx": () => import("./../../../src/pages/heli-celebrations-other-services.tsx" /* webpackChunkName: "component---src-pages-heli-celebrations-other-services-tsx" */),
  "component---src-pages-heli-vacations-and-tourisms-tsx": () => import("./../../../src/pages/heli-vacations-and-tourisms.tsx" /* webpackChunkName: "component---src-pages-heli-vacations-and-tourisms-tsx" */),
  "component---src-pages-heli-weddings-tsx": () => import("./../../../src/pages/heli-weddings.tsx" /* webpackChunkName: "component---src-pages-heli-weddings-tsx" */),
  "component---src-pages-helitaxii-for-pilgrimage-tsx": () => import("./../../../src/pages/helitaxii-for-pilgrimage.tsx" /* webpackChunkName: "component---src-pages-helitaxii-for-pilgrimage-tsx" */),
  "component---src-pages-helitaxii-pilgrimage-yatra-tsx": () => import("./../../../src/pages/helitaxii-pilgrimage-yatra.tsx" /* webpackChunkName: "component---src-pages-helitaxii-pilgrimage-yatra-tsx" */),
  "component---src-pages-helitaxii-shuttles-and-charters-tsx": () => import("./../../../src/pages/helitaxii-shuttles-and-charters.tsx" /* webpackChunkName: "component---src-pages-helitaxii-shuttles-and-charters-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-user-charter-booking-tsx": () => import("./../../../src/pages/user/charter-booking.tsx" /* webpackChunkName: "component---src-pages-user-charter-booking-tsx" */)
}

